<template>
  <div>
    <el-container class="login">
      <el-header class=".header" height="auto" style="padding:0px">
        <!-- <Header :headerState="true" /> -->
      </el-header>
      <el-main style="padding:0px">
        <!-- <Nuxt /> -->
        <!-- <Backtop /> -->
      </el-main>
      <el-footer style="padding:0;">
        <div class="footer">
          <div class="footer_left">
            <div class="footer_site">
              <span><i class="icon_email"></i>kefu@zkfy.com</span>
              <span><i class="icon_phone"></i>010-61934588</span>
              <span><i class="icon_site"></i>洪山区关山街邮科院路88号烽火创新谷特种器件楼1楼101</span>
            </div>
            <div class="footer_records">
              <p>@中科凡语(武汉)科技有限公司（WuHan Fanyu Technology Co., Ltd）</p>
              <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011102004918"
                 style="display:inline-block;text-decoration:none;">
                <img src="~@/assets/imgs/beian.png" style="float:left; width: 16px; height: 16px;"/>
                <p style="float:left; margin: 0px 10px 0px 0; color:#939393;">鄂公网安备 42011102004918号</p>
              </a>
              <a style="display:inline-block;text-decoration:none;">
                <p style="float:left; margin: 0px 10px 0px 0; color:#939393;"> | </p>
              </a>
              <a target="_blank" href="https://beian.miit.gov.cn/" style="display:inline-block;text-decoration:none;">
                <p style="float:left; margin: 0px 10px 0px 0; color:#939393;">鄂ICP备2022011223号-1</p>
              </a>
              <!-- <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备18045685号-4</a> -->

            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
// import Header from '../components/Header'
export default {
  // 组件参数
  props: {},
  // JS片段
  mixins: [],
  // 局部注册的组件
  components: {
    //  Header
  },
  // 组件状态值
  data () {
    return {};
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {},
  // 预请求数据
  created () { },
  // 组件方法
  mounted () { },
};
</script> 

<style scoped lang='scss'>
.footer {
  height: 248px;
  width: 100%;
  text-align: center;
  background: #1e252f;
  display: flex;
  justify-content: center;
  align-items: center;
  .footer_left {
    width: 42%;
    min-width: 610px;
    margin-right: 114px;
    .footer_site {
      font-size: 12px;
      font-weight: normal;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #393d40;
      padding: 0px 5px 10px;
      margin-bottom: 20px;
      .icon_email {
        display: inline-block;
        width: 16px;
        height: 11px;
        background: url("@/assets/imgs/home/youxiang-icon.png") center no-repeat;
        background-size: contain;
        position: relative;
        top: 2px;
        margin-right: 5px;
      }
      .icon_phone {
        display: inline-block;
        width: 15px;
        height: 15px;
        background: url("@/assets/imgs/home/lianxifangshi-icon.png") center
          no-repeat;
        background-size: contain;
        position: relative;
        top: 2px;
        margin-right: 5px;
      }
      .icon_site {
        display: inline-block;
        width: 11px;
        height: 17px;
        background: url("@/assets/imgs/home/dizhi -icon.png") center no-repeat;
        background-size: contain;
        position: relative;
        top: 2px;
        margin-right: 5px;
      }
    }
    .footer_records {
      font-size: 14px;
      color: #ccc;

      a {
        color: #ccc;
      }
      a:hover {
        color: #0a7deb;
      }
    }
  }
  .footer_right {
    .footer_right_tit {
      text-align: left;
      font-size: 18px;

      color: #ffffff;
    }
    .footer_code {
      img {
        width: 129px;
        height: 127px;
        border: 1px solid #fff;
      }
      .footer_code_item {
        float: left;
        margin-top: 14px;
        margin-right: 14px;
        font-size: 14px;

        color: #80848a;
      }
    }
  }
}
</style>
