<template>
  <div id="app">
    <Header />
    <router-view />
    <ReleasePlatform />
  </div>
</template>
<script>
import Header from './components/Header'
import ReleasePlatform from './components/releasePlatform'
export default {
  name: "App",
  components: {
    Header,
    ReleasePlatform
  },
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>
<style lang="scss">
#app {
  // height: 100%;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
