<template>
  <div class="fy_header">
    <ul class="fy_header_nav clearf">
      <li v-if="!userIcon" class="nav_wrapper user" @click="goLogin()"><span class="user_icon"></span>登录<span class="string_box"></span>注册</li>
      <li v-else class="nav_wrapper user_img_box">
        <el-popover class="user_popover" placement="top" width="200" trigger="hover">
          <ul class="user_but">
            <li class="user_name pointer" @click="goCenter()">
              <i class="user_icon"></i><span>{{user.nickName}}</span>
            </li>
            <li class="user_out pointer" @click="out">
              <i class="user_out_icon"></i> 退出登录
            </li>
          </ul>
          <div slot="reference" class="user_icon_box">
            <img :src="userIcon" alt="头像">
            <i class="el-icon-arrow-down"></i>
          </div>
        </el-popover>
      </li>
      <template>
        <li class="nav_wrapper">
          <span @click.stop="$router.push({ name: 'HomeView' })">企业</span>
        </li>
        <li class="nav_wrapper">
          <span @click.stop="$router.push({ name: 'infoPublish' })">个人</span>
        </li>
      </template>
      <li class="nav_wrapper intro">
        <span class="iconfont icon-logo-baise pointer" @click="goHome()"></span>
        <span style="cursor:pointer;" @click="goHome()">飞译 — AI赋能翻译，让语言没有边界</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { login, getCheckedLoginUser } from '@/api/login'
export default {
  // 局部注册的组件
  components: {
    //  Seek 
  },
  props: {
    back: {
      type: String,
      default: '#2b303b'
    },
    headerState: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      user: {
        id: 0,
        nickName: '游客',
        avatar: '',
        userAuthAuditStatus: 0,
        companyAuthAuditStatus: 0
      }
    }
  },
  async mounted () {
    let myUser = localStorage.getItem("myUser");
    if(myUser != null){
      this.user = JSON.parse(myUser)
    }
    // 确保当前登录状态
    const { result, resultCode } = await getCheckedLoginUser()
    if (resultCode === '1') {
      if (result && result !== null) {
        const val = JSON.stringify(result)
        localStorage.setItem('myUser', val)
        this.user = result
      } else {
        localStorage.removeItem('myUser')
      }
    }
  },
  computed: {
    back_ () {
      return this.back
    },
    userIcon () {
      return this.user && this.user.id !== 0 ? this.user.avatar : false
    },
    homeButIsShow () {
      const path = this.$route.path.toLowerCase()
      return path !== '/home' && path !== '/consulting' && path !== '/translation' &&
        path !== '/bilingual' && path !== '/language' && path !== '/search'
    }
  },
  methods: {
    // 退出登录
    out () {
      localStorage.removeItem('myUser')
      const callback = encodeURIComponent(`${window.location.origin}/logout?client_name=${casClientName}`)
      window.location.href = `${casUrl}/logout?service=${callback}`
    },
    goHome () {
      // this.$store.commit('user/CURRENT', 0)
      // this.$store.dispatch('user/hideSidebar', true) // 显示搜索框
      // this.$router.push({ name: 'home' })
    },
    async goLogin () {
      let {result} = await login()
      if(result && result.id){
        this.user = result;
        localStorage.setItem("myUser", JSON.stringify(result))
      }
      // this.$router.push({path:"/perCenter"})
    },
    goCenter () {
      this.$store.commit('user/CURRENT', 1)
      this.$router.push({ name: 'redirect', query: { route: '/perCenter' } })
      // this.$router.push({path:"/perCenter"})
    },
    goAuthentication () {
      this.$store.commit('user/CURRENT', '2-2')
      this.$router.push({ name: 'redirect', query: { route: '/perCenter/authentication' } })
      // this.$router.push({path:"/perCenter/authentication"})
    },
    goCompanyAuthentication () {
      this.$store.commit('user/CURRENT', 4)
      this.$router.push({ name: 'redirect', query: { route: '/perCenter/companyAuthentication' } })
      // this.$router.push({path:"/perCenter/companyAuthentication"})
    }
  }
}
</script>

<style lang="scss" scoped>
.user_icon_box {
  // background: url("../../assets/imgs/home/touxiang-kuang-icon.png") center no-repeat;
  // width: 40px;
  // height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-weight: 600;
  }
}
.fy_header {
  width: 100%;
  height: 46px;
  background: #2b303b;
  display: flex;
  align-items: center;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
}
.fy_header_nav {
  width: 100%;
  padding: 0 94px;
  margin: 0 auto;
  //   height: 46px;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  .nav_wrapper {
    list-style: none;
    float: right;
    font-size: 12px;
    color: #f5f5f5;
    height: 46px;
    line-height: 46px;
    margin-right: 40px;
    cursor: pointer;
    .aut_icon {
      width: 15px;
      height: 15px;
      display: inline-block;
      position: relative;
      top: 3px;
      background: url("../../assets/imgs/home/huiyuan-renzheng+.png") center
        no-repeat;
      background-size: contain;
    }
    .aut_icon_fa {
      width: 15px;
      height: 15px;
      display: inline-block;
      position: relative;
      top: 3px;
      background: url("../../assets/imgs/home/huiyuan-+.png") center no-repeat;
      background-size: contain;
    }
  }
  .nav_wrapper:hover {
    color: #0a7deb;
    font-weight: bold;
  }
  .intro:hover {
    color: #f5f5f5;
    span {
      font-weight: normal;
    }
    span:nth-child(2) {
      font-weight: bold;
    }
    button {
      color: #0a7deb;
    }
  }
  .user {
    // background: #2f353e;
    margin-right: 0px;
    float: right;
    display: flex;
    align-items: center;
    padding: 0 9px;
    .string_box {
      width: 0px;
      height: 12px;
      border-left: 1px solid #ffffff;
      margin: 0 4px;
    }
    &:hover .string_box {
      border-color: #0a7deb;
    }
    .user_icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url("../../assets/imgs/home/denglu-tuichu-icon.png");
      background-size: cover;
      margin-right: 9px;
    }
  }
  .user_img_box {
    display: flex;
    align-items: center;
    margin-right: 0px;
    margin-left: 40px;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
  .intro {
    float: left;
    display: flex;
    align-items: center;
    cursor: auto;
    .iconfont {
      font-size: 28px;
      margin-right: 42px;
    }
    .back_home_button {
      float: right;
      background: transparent;
      color: #ffffff;
      display: flex;
      align-items: center;
      .back_home_icon {
        display: inline-block;
        height: 14px;
        width: 14px;
        background: url("../../assets/imgs/home/fanhui-shouye-icon.png") center
          no-repeat;
        background-size: cover;
        margin: 0 10px 0px 20px;
      }
    }
  }
}
.user_but {
  i {
    width: 13px;
    height: 13px;
    display: inline-block;
    margin-right: 5px;
  }
  .user_name {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 12px;
    .user_icon {
      background: url("../../assets/imgs/home/geren-mingzi-icon.png") center
        no-repeat;
      background-size: cover;
    }
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 160px;
    }
  }
  .user_name:hover {
    color: #0a7deb;
    .user_icon {
      background: url("../../assets/imgs/home/geren-mingzi-icon-xuanzhong.png")
        center no-repeat;
      background-size: cover;
    }
  }
  .user_out {
    padding-top: 12px;
    .user_out_icon {
      background: url("../../assets/imgs/home/tuichu-icon.png") center no-repeat;
      background-size: cover;
      // float: left;
    }
  }
  .user_out:hover {
    color: #0a7deb;
    .user_out_icon {
      background: url("../../assets/imgs/home/tuichu-icon-xuanzhong.png") center
        no-repeat;
      background-size: cover;
    }
  }

  li {
    height: 38px;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
}
.nav_seek {
  transform: translateY(-5px);
}
</style>
