import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/HomeView',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/infoPublish',
    name: 'infoPublish',
    component: () => import(/* webpackChunkName: "about" */ '../views/infoPublish.vue')
  },
  {
    path: '/uploadOrder',
    name: 'uploadOrder',
    component: () => import(/* webpackChunkName: "about" */ '../views/uploadOrder.vue')
  },
  {
    path: '/waifPages',
    name: 'waifPages',
    component: () => import(/* webpackChunkName: "about" */ '../views/waifPages.vue')
  },
  {
    path: '/buildPages',
    name: 'buildPages',
    component: () => import(/* webpackChunkName: "about" */ '../views/buildPages.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
