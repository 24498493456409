import axios from 'axios'

const service = axios.create({
  timeout: 100000,
  baseURL: '/pt'
})

service.interceptors.request.use((config) => {
  if (casEnabled) {
    config.headers['redirect-url'] = window.location.href.length > 1000 ? window.location.origin : window.location.href;
    config.headers['is-ajax-request'] = true;
    config.headers['client-name'] = casClientName;
  }
  console.log(casEnabled, 'config', config);

  return config
}, (error) => {
  return Promise.reject(error)
})

service.interceptors.response.use(response => {
  const {
    data,
    status,
    statusText
  } = response
  if (casEnabled && status === 401) {
    window.location.href = response.headers.location;
    return true
  }
  return data
}, (error) => {
  if (casEnabled && error.response.status === 401) {
    window.location.href = error.response.headers.location;
    return true
  }
  return Promise.reject(error)
})

export default service