import request from "@/utils/request";

export const login = () => {
  return request({
    method: 'GET',
    url: "/api/v1/centre/getSessionUser",
  })
}

export const getCheckedLoginUser = () => {
  return request({
    method: 'GET',
    url: "/user/getCheckedLoginUser",
  })
}