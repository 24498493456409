<template>
  <div class="HomeView">
    <!-- <Header /> -->
    <div class="enterpriseInfoRelease">
      <div class="swiper">
       <el-carousel height="450px">
          <el-carousel-item>
            <img src="@/assets/imgs/enterpriseInfoRelease/swiper1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/imgs/enterpriseInfoRelease/swiper2.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/imgs/enterpriseInfoRelease/swiper3.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/imgs/enterpriseInfoRelease/swiper4.png" alt="">
          </el-carousel-item>
<!--          <el-carousel-item>-->
<!--            <img src="@/assets/imgs/enterpriseInfoRelease/swiper5.png" alt="">-->
<!--          </el-carousel-item>-->
        </el-carousel>
      </div> -->
      <div class="head-box">
        <div class="search-box">
          <input v-model="seek" class="search-input" type="text" placeholder="搜索全站" @keyup.enter="goSeek">
          <div class="search-btn" @click="goSeek">
            <img src="@/assets/imgs/enterpriseInfoRelease/search.png" alt="">
          </div>
        </div>
        <div class="user-num-box">
          <div class="user-item blue">
            <img src="@/assets/imgs/enterpriseInfoRelease/user1.png" alt="">
            <div class="user-item-text">译员</div>
            <div class="user-item-num">21000+</div>
          </div>
          <div class="user-item yellow">
            <img src="@/assets/imgs/enterpriseInfoRelease/user2.png" alt="">
            <div class="user-item-text">审校</div>
            <div class="user-item-num">13000+</div>
          </div>
        </div>
        <div class="file-upload-box">
          <div class="file-upload-item">
            <div class="file-upload-title">文档翻译</div>
            <div class="file-upload-info">数亿级别的平行语料库，覆盖50+个语种，多个细分领域</div>
            <div class="file-upload-tag-box">
              <div class="file-upload-tag-item">公司介绍</div>
              <div class="file-upload-tag-item">合同标书</div>
              <div class="file-upload-tag-item">证件资料</div>
              <div class="file-upload-tag-item">专业论文</div>
              <div class="file-upload-tag-item">简历简介</div>
            </div>
            <div class="file-upload-btn" @click="$router.push('/uploadOrder')">
              立即上传
            </div>
            <div class="file-upload-border"></div>
          </div>
          <div class="file-upload-item">
            <div class="file-upload-title">文件翻译</div>
            <div class="file-upload-info">数亿级别的平行语料库，覆盖50+个语种，多个细分领域</div>
            <div class="file-upload-tag-box">
              <div class="file-upload-tag-item">公司介绍</div>
              <div class="file-upload-tag-item">合同标书</div>
              <div class="file-upload-tag-item">证件资料</div>
              <div class="file-upload-tag-item">专业论文</div>
              <div class="file-upload-tag-item">简历简介</div>
            </div>
            <div class="file-upload-btn" @click="$router.push('/uploadOrder')">
              立即上传
            </div>
            <div class="file-upload-border"></div>
          </div>
          <div class="file-upload-item">
            <div class="file-upload-title">应急文本翻译</div>
            <div class="file-upload-info">适用于日常用语、工作沟通、地址信息等紧急的短文本，保留文本原格式</div>
            <div class="file-upload-tag-box">
              <div class="file-upload-tag-item">公司介绍</div>
              <div class="file-upload-tag-item">合同标书</div>
              <div class="file-upload-tag-item">证件资料</div>
              <div class="file-upload-tag-item">专业论文</div>
              <div class="file-upload-tag-item">简历简介</div>
            </div>
            <div class="file-upload-btn" @click="$router.push('/uploadOrder')">
              立即上传
            </div>
            <div class="file-upload-border"></div>
          </div>
          <div class="file-upload-item">
            <div class="file-upload-title">译后编辑</div>
            <div class="file-upload-info">在机器翻译的基础上，人工编辑润色，效率高</div>
            <div class="file-upload-tag-box">
              <div class="file-upload-tag-item">公司介绍</div>
              <div class="file-upload-tag-item">合同标书</div>
              <div class="file-upload-tag-item">证件资料</div>
              <div class="file-upload-tag-item">专业论文</div>
              <div class="file-upload-tag-item">简历简介</div>
            </div>
            <div class="file-upload-btn" @click="$router.push('/uploadOrder')">
              立即上传
            </div>
            <div class="file-upload-border"></div>
          </div>

        </div>
        <div class="red-info-box">
          <div class="red-info-item">
            <div class="red-info-label">视频翻译</div>
            <div class="red-info-msg">AI语音识别技术，更精准的字幕制作</div>
            <div class="red-info-goto" @click="$router.push('/buildPages')">立即咨询>></div>
          </div>
          <div class="red-info-item">
            <div class="red-info-label">同传</div>
            <div class="red-info-msg">数亿级别的平行语料库，覆盖50+个语种，多个细分领域</div>
            <div class="red-info-goto" @click="$router.push('/buildPages')">立即咨询>></div>
          </div>
          <div class="red-info-item">
            <div class="red-info-label">本地化翻译</div>
            <div class="red-info-msg">全球多个国家地区的译员团队助力，更贴近本地化的语言习惯</div>
            <div class="red-info-goto" @click="$router.push('/buildPages')">立即咨询>></div>
          </div>
        </div>
      </div>
      <div class="field-box">
        <div class="field-item">
          <div class="field-title">
            <img src="@/assets/imgs/enterpriseInfoRelease/onlineRetailers.png" alt="">
            <div class="field-text">电子商务</div>
          </div>
          <div class="field-main-box">
            <div class="hot-tag">
              翻译热词
              <img src="@/assets/imgs/enterpriseInfoRelease/hot.png" alt="">
            </div>
            <img src="@/assets/imgs/enterpriseInfoRelease/introduceOnlineRetailers.png" alt="">
            <div class="field-main-msg">
              <div class="field-text-box">
                <span class="field-text-primary">Business Software / </span>
                <span class="field-text-Highlight">商业软件</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">Browser / </span>
                <span class="field-text-Highlight">浏览器</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">BGP / </span>
                <span class="field-text-Highlight">边界网关协议</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">Bridge / </span>
                <span class="field-text-Highlight">网桥</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">Bookstore Online / </span>
                <span class="field-text-Highlight">网上书店</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">exploit web / </span>
                <span class="field-text-Highlight">拓展网络</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">email marketing / </span>
                <span class="field-text-Highlight">电子邮件营销</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">market research / </span>
                <span class="field-text-Highlight">市场调研</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">enhance overall marketing message / </span>
                <span class="field-text-Highlight">提高整体营销信息</span>
              </div>
            </div>
          </div>
        </div>
        <div class="field-item" style="margin-left:18px">
          <div class="field-title">
            <img src="@/assets/imgs/enterpriseInfoRelease/patent.png" alt="">
            <div class="field-text">专利产权</div>
          </div>
          <div class="field-main-box">
            <div class="hot-tag">
              翻译热词
              <img src="@/assets/imgs/enterpriseInfoRelease/hot.png" alt="">
            </div>
            <img src="@/assets/imgs/enterpriseInfoRelease/introducePatent.png" alt="">
            <div class="field-main-msg">
              <div class="field-text-box">
                <span class="field-text-primary">basic patent / </span>
                <span class="field-text-Highlight">基本专利</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">Berne Convention / </span>
                <span class="field-text-Highlight">伯尔尼公约</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">copyright / </span>
                <span class="field-text-Highlight">版权</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">right to exclusive use of trademark / </span>
                <span class="field-text-Highlight">商标专用权</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">patent / </span>
                <span class="field-text-Highlight">专利权</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">Berne Union / </span>
                <span class="field-text-Highlight">伯尔尼联盟</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">best mode / </span>
                <span class="field-text-Highlight">最佳方式</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">intellectual property / </span>
                <span class="field-text-Highlight">知识产权</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">patent dispute / </span>
                <span class="field-text-Highlight">专利权纠纷</span>
              </div>
            </div>
          </div>
        </div>
        <div class="field-item" style="width:100%;margin:25px 0 0 0">
          <div class="field-title">
            <img src="@/assets/imgs/enterpriseInfoRelease/currency.png" alt="">
            <div class="field-text">通用领域</div>
          </div>
          <div class="field-main-box">
            <div class="hot-tag">
              翻译热词
              <img src="@/assets/imgs/enterpriseInfoRelease/hot.png" alt="">
            </div>
            <img src="@/assets/imgs/enterpriseInfoRelease/introduceCurrency.png" alt="">
            <div class="field-main-msg">
              <div class="field-text-box">
                <span class="field-text-primary">The Silk Road Economic Belt and the 21st-Century Maritime Silk Road / </span>
                <span class="field-text-Highlight">一带一路</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">the battle of epidemic prevention and control / </span>
                <span class="field-text-Highlight">疫情防控阻击战</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">The Age of Awakening / </span>
                <span class="field-text-Highlight">觉醒年代</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">metaverse / </span>
                <span class="field-text-Highlight">元宇宙</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">Lucid waters and lush mountains are invaluable assets / </span>
                <span class="field-text-Highlight">绿水青山就是金山银山</span>
              </div>
              <div class="field-text-box">
                <span class="field-text-primary">ease the burden of excessive homework and off-campus tutoring for students undergoing compulsory education / </span>
                <span class="field-text-Highlight">双减</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="info-box">
        <div class="info-title">
          <img src="@/assets/imgs/enterpriseInfoRelease/translate.png" alt="">
          <div class="field-text">翻译流程</div>
        </div>
        <img class="technological-process" src="@/assets/imgs/enterpriseInfoRelease/technological-process.png" alt="">
        <div class="info-title">
          <img src="@/assets/imgs/enterpriseInfoRelease/listTop.png" alt="">
          <div class="field-text">翻译榜单</div>
        </div>
        <div class="list-top-box">
          <div class="tab-box">
            <div :class="['tab-item',selectTab===1? 'select-tab-item':'']" @click="search(1)">每日</div>
            <div :class="['tab-item',selectTab===2? 'select-tab-item':'']" @click="search(2)">每周</div>
            <div :class="['tab-item',selectTab===3? 'select-tab-item':'']" @click="search(3)">每月</div>
          </div>
          <div class="top-list-box">
            <div class="top-list-main" v-if="topList.length>0">
              <div class="top-list-item" v-for="(item,index) in topList" :key="index">
                <img v-if="item.head===1" src="@/assets/imgs/enterpriseInfoRelease/headPortrait1.png" alt="">
                <img v-if="item.head===2" src="@/assets/imgs/enterpriseInfoRelease/headPortrait2.png" alt="">
                <img v-if="item.head===3" src="@/assets/imgs/enterpriseInfoRelease/headPortrait3.png" alt="">
                <img v-if="item.head===4" src="@/assets/imgs/enterpriseInfoRelease/headPortrait4.png" alt="">
                <img v-if="item.head===5" src="@/assets/imgs/enterpriseInfoRelease/headPortrait5.png" alt="">
                <div class="list-text">{{item.name}}</div>
                <div class="list-text">擅长领域：{{item.field}}</div>
                <div class="list-text">翻译经验：{{item.experience}}</div>
                <div class="list-text">翻译字数：{{item.words}}字</div>
                <div class="list-text">准确度：{{item.standard}}%</div>
                <div class="list-text">评分：{{item.score}}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div :class="['flex-box',isShowFlex?'flex-box-show':'']">
        <div class="flex-btn" @click="isShowFlex=!isShowFlex">
          <img :class="['flex-btn-img',isShowFlex?'flex-btn-img-show':'']" src="@/assets/imgs/enterpriseInfoRelease/flex-btn.png" alt="">
        </div>
        <div class="flex-item">
          <img src="@/assets/imgs/enterpriseInfoRelease/flex1.png" alt="">
          <div class="flex-item-label">丰富的语料资源</div>
          <div class="flex-item-msg">专数亿级别的平行语料库</div>
          <div class="flex-item-msg">覆盖50+个语种</div>
          <div class="flex-item-msg">多个细分领域</div>
          <div class="flex-item-border"></div>
        </div>
        <div class="flex-item">
          <img src="@/assets/imgs/enterpriseInfoRelease/flex2.png" alt="">
          <div class="flex-item-label">快速的迁移能力</div>
          <div class="flex-item-msg">专业的语料加工</div>
          <div class="flex-item-msg">精准的知识挖掘</div>
          <div class="flex-item-msg">丰富的开发经验</div>
          <div class="flex-item-border"></div>
        </div>
        <div class="flex-item">
          <img src="@/assets/imgs/enterpriseInfoRelease/flex3.png" alt="">
          <div class="flex-item-label">先进的技术框架</div>
          <div class="flex-item-msg">基于神经网络的机器翻译框架</div>
          <div class="flex-item-msg">基于预训练语言模型的分析算法</div>
          <div class="flex-item-border"></div>
        </div>
        <div class="flex-item">
          <img src="@/assets/imgs/enterpriseInfoRelease/flex4.png" alt="">
          <div class="flex-item-label">完备的基础算法</div>
          <div class="flex-item-msg">多语言词法分析、句法</div>
          <div class="flex-item-msg">分析、语义分析工具</div>
          <div class="flex-item-msg">聚类、分类工具</div>
          <div class="flex-item-msg">自动摘要、信息检索工具</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import Header from '../components/Header'
export default {
  name: 'HomeView',
  components: {
    // Header
  },
  data () {
    return {
      seek: null,
      selectTab: 1,
      isShowFlex: false,
      topList: [
        { head: 1, name: '张**', field: '通用领域', experience: '1~3年', words: '87795', standard: '92.3', score: '9.6' },
        { head: 2, name: '刘**', field: '电子商务', experience: '3~5年', words: '78542', standard: '91.6', score: '5.6' },
        { head: 3, name: '赵**', field: '专利产权', experience: '5~8年', words: '75485', standard: '90.1', score: '7.6' },
        { head: 4, name: '伍**', field: '电子商务', experience: '1~3年', words: '15230', standard: '92.7', score: '5.7' },
        { head: 5, name: '曾**', field: '通用领域', experience: '3~5年', words: '88542', standard: '91.7', score: '3.6' },
        { head: 1, name: '冯**', field: '专利产权', experience: '5~8年', words: '54256', standard: '91.7', score: '9.8' },
        { head: 2, name: '张**', field: '通用领域', experience: '1~3年', words: '87795', standard: '92.3', score: '9.6' },
        { head: 3, name: '刘**', field: '电子商务', experience: '3~5年', words: '78542', standard: '91.6', score: '5.6' },
        { head: 4, name: '赵**', field: '专利产权', experience: '5~8年', words: '75485', standard: '90.1', score: '7.6' },
        { head: 5, name: '伍**', field: '电子商务', experience: '1~3年', words: '15230', standard: '92.7', score: '5.7' },
        { head: 1, name: '曾**', field: '通用领域', experience: '3~5年', words: '88542', standard: '91.7', score: '3.6' },
        { head: 2, name: '冯**', field: '专利产权', experience: '5~8年', words: '54256', standard: '91.7', score: '9.8' },
        { head: 3, name: '张**', field: '通用领域', experience: '1~3年', words: '87795', standard: '92.3', score: '9.6' },
        { head: 4, name: '刘**', field: '电子商务', experience: '3~5年', words: '78542', standard: '91.6', score: '5.6' },
        { head: 5, name: '赵**', field: '专利产权', experience: '5~8年', words: '75485', standard: '90.1', score: '7.6' },
        { head: 1, name: '伍**', field: '电子商务', experience: '1~3年', words: '15230', standard: '92.7', score: '5.7' },
        { head: 2, name: '曾**', field: '通用领域', experience: '3~5年', words: '88542', standard: '91.7', score: '3.6' },
        { head: 3, name: '冯**', field: '专利产权', experience: '5~8年', words: '54256', standard: '91.7', score: '9.8' },
        { head: 4, name: '张**', field: '通用领域', experience: '1~3年', words: '87795', standard: '92.3', score: '9.6' },
        { head: 5, name: '刘**', field: '电子商务', experience: '3~5年', words: '78542', standard: '91.6', score: '5.6' },
        { head: 1, name: '赵**', field: '专利产权', experience: '5~8年', words: '75485', standard: '90.1', score: '7.6' },
        { head: 2, name: '伍**', field: '电子商务', experience: '1~3年', words: '15230', standard: '92.7', score: '5.7' },
        { head: 3, name: '曾**', field: '通用领域', experience: '3~5年', words: '88542', standard: '91.7', score: '3.6' },
        { head: 4, name: '冯**', field: '专利产权', experience: '5~8年', words: '54256', standard: '91.7', score: '9.8' },
        { head: 5, name: '张**', field: '通用领域', experience: '1~3年', words: '87795', standard: '92.3', score: '9.6' },
        { head: 2, name: '刘**', field: '电子商务', experience: '3~5年', words: '78542', standard: '91.6', score: '5.6' },
        { head: 1, name: '赵**', field: '专利产权', experience: '5~8年', words: '75485', standard: '90.1', score: '7.6' },
        { head: 3, name: '伍**', field: '电子商务', experience: '1~3年', words: '15230', standard: '92.7', score: '5.7' },
        { head: 4, name: '曾**', field: '通用领域', experience: '3~5年', words: '88542', standard: '91.7', score: '3.6' },
        { head: 5, name: '冯**', field: '专利产权', experience: '5~8年', words: '54256', standard: '91.7', score: '9.8' },
        { head: 1, name: '张**', field: '通用领域', experience: '1~3年', words: '87795', standard: '92.3', score: '9.6' },
        { head: 2, name: '刘**', field: '电子商务', experience: '3~5年', words: '78542', standard: '91.6', score: '5.6' },
        { head: 3, name: '赵**', field: '专利产权', experience: '5~8年', words: '75485', standard: '90.1', score: '7.6' },
        { head: 4, name: '伍**', field: '电子商务', experience: '1~3年', words: '15230', standard: '92.7', score: '5.7' },
        { head: 5, name: '曾**', field: '通用领域', experience: '3~5年', words: '88542', standard: '91.7', score: '3.6' },
        { head: 1, name: '冯**', field: '专利产权', experience: '5~8年', words: '54256', standard: '91.7', score: '9.8' },
        { head: 2, name: '张**', field: '通用领域', experience: '1~3年', words: '87795', standard: '92.3', score: '9.6' },
        { head: 3, name: '刘**', field: '电子商务', experience: '3~5年', words: '78542', standard: '91.6', score: '5.6' },
        { head: 4, name: '赵**', field: '专利产权', experience: '5~8年', words: '75485', standard: '90.1', score: '7.6' },
        { head: 5, name: '伍**', field: '电子商务', experience: '1~3年', words: '15230', standard: '92.7', score: '5.7' },
        { head: 1, name: '曾**', field: '通用领域', experience: '3~5年', words: '88542', standard: '91.7', score: '3.6' },
        { head: 2, name: '冯**', field: '专利产权', experience: '5~8年', words: '54256', standard: '91.7', score: '9.8' }

      ]
    }
  },
  methods: {
    async search (num) {
      this.selectTab = num
      const arr = await this.shuffle(this.topList)
      this.topList = []
      setTimeout(() => {
        this.topList = arr
      }, 300)
    },
    shuffle (arr) {
      let length = arr.length
      let randomIndex
      let temp
      while (length) {
        randomIndex = Math.floor(Math.random() * (length--))
        temp = arr[randomIndex]
        arr[randomIndex] = arr[length]
        arr[length] = temp
      }
      return arr
    },
    goSeek () {
      this.$router.push('/buildPages')
      // this.$store.dispatch('user/hideSidebar', false)
      // const _this = this
      // const sear = JSON.stringify(this.seek)
      // this.$router.push({ name: 'search', params: { sear, Highlight: _this.current } })
      // this.seek = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.enterpriseInfoRelease {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  .swiper {
    height: 450px;
    background: #bababa;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .head-box {
    max-width: 1200px;
    margin: 0% auto;
    background: #f7f7f7;
    padding-top: 80px;
    padding-bottom: 20px;
    background-image: url("@/assets/imgs/enterpriseInfoRelease/head-box-bg.png");
    background-size: 100%;
    position: relative;
    .search-box {
      width: 720px;
      margin: 0% auto;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      .search-input {
        outline: none;
        border: none;
        width: 600px;
        height: 60px;
        background: #ffffff;
        border-radius: 4px 0px 0px 4px;
        text-indent: 40px;
        font-size: 22px;
        color: #333333;
        &::placeholder {
          color: #9f9f9f;
        }
      }
      .search-btn {
        width: 120px;
        height: 60px;
        background: #0078fc;
        border-radius: 0px 4px 4px 0px;
        position: relative;
        cursor: pointer;
        img {
          position: absolute;
          width: 50px;
          height: 50px;
          top: 7px;
          left: 35px;
        }
      }
    }
    .user-num-box {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .user-item {
        display: inline-block;
        width: 146px;
        height: 60px;
        border-radius: 4px;
        margin: 0% 20px;
        position: relative;
        img {
          width: 47px;
          height: 47px;
          position: absolute;
          top: 9px;
          left: 7px;
        }
        .user-item-text {
          margin: 11px 0 0 55px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 100%;
        }
        .user-item-num {
          margin: 5px 0 0 55px;
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 100%;
        }
      }
      .blue {
        background: #0078fc;
      }
      .yellow {
        background: #ff9232;
      }
    }
    .file-upload-box {
      display: flex;
      flex-wrap: wrap;
      margin: 40px 0 0 20px;

      .file-upload-item {
        display: inline-block;
        vertical-align: top;
        margin: 0 20px 20px 0;
        width: calc(50% - 20px);
        height: 210px;
        background-image: url("@/assets/imgs/enterpriseInfoRelease/head-box-bg1.png");
        background-size: 100%;
        box-shadow: 0px 10px 13px 0px rgba(48, 153, 250, 0.4);
        border-radius: 4px;
        position: relative;
        .file-upload-title {
          font-size: 22px;
          font-weight: bold;
          color: #ffffff;
          margin: 30px 0 0 30px;
          line-height: 100%;
        }
        .file-upload-info {
          font-size: 16px;
          color: #ffffff;
          margin: 20px 0 20px 30px;
          line-height: 100%;
        }
        .file-upload-tag-box {
          display: flex;
          flex-wrap: wrap;
          width: 70%;
          margin-left: 30px;
          .file-upload-tag-item {
            width: 96px;
            height: 29px;
            background: #ffffff;
            box-shadow: 0px 2px 6px 0px rgba(4, 0, 0, 0.05);
            border-radius: 4px;
            font-size: 14px;
            color: #666666;
            line-height: 29px;
            text-align: center;
            margin: 0 10px 10px 0;
          }
        }
        .file-upload-btn {
          width: 96px;
          height: 36px;
          background: #5aa9ff;
          border: 1px solid #ffffff;
          border-radius: 4px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 36px;
          text-align: center;
          cursor: pointer;
          position: absolute;
          bottom: 30px;
          right: 20px;
        }
        .file-upload-border {
          width: 60px;
          height: 6px;
          background: #ff9232;
          border-radius: 2px;
          position: absolute;
          top: -2px;
          left: 30px;
        }
      }
    }
    .red-info-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 20px;

      .red-info-item {
        flex: 1;
        position: relative;
        font-family: Microsoft YaHei;
        height: 120px;
        background: #ffffff;
        border-radius: 4px;
        padding: 0 30px;
        .red-info-label {
          font-size: 22px;
          font-weight: bold;
          margin-top: 20px;
          line-height: 100%;
          color: #333333;
        }
        .red-info-msg {
          font-size: 16px;
          margin-top: 8px;
          line-height: 25px;
          color: #666666;
        }
        .red-info-goto {
          font-size: 14px;
          text-decoration: underline;
          position: absolute;
          right: 10px;
          bottom: 8px;
          cursor: pointer;
          color: #333333;
        }
      }
      .red-info-item + .red-info-item {
        margin-left: 10px;
      }
    }
  }
  .field-box {
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0% auto;
    background: #f7f7f7;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .field-item {
      width: calc(50% - 9px);
      .field-title {
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
        .field-text {
          margin-left: 7px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 30px;
        }
      }
      .field-main-box {
        margin-top: 14px;
        border: 1px solid #dedede;
        border-radius: 4px;
        background-color: #ffffff;
        position: relative;
        .hot-tag {
          width: 110px;
          height: 32px;
          background: #ffffff;
          box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.4);
          border-radius: 0px 0px 6px 6px;
          position: absolute;
          top: -8px;
          right: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ff8a00;
          img {
            width: 11px;
            height: 13px;
            margin-left: 7px;
          }
        }
        img {
          width: 100%;
          height: 220px;
          object-fit: cover;
          display: block;
        }
        .field-main-msg {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 100%;
          padding: 19px 0 0 19px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .field-text-box {
            margin-right: 20px;
            margin-bottom: 20px;
            .field-text-primary {
              color: #333333;
              white-space: nowrap;
            }
            .field-text-Highlight {
              color: #999999;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .info-box {
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0% auto;
    padding-bottom: 40px;

    .info-title {
      display: flex;
      align-items: center;
      padding: 20px;
      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
      .field-text {
        margin-left: 7px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
      }
    }
    .technological-process {
      width: 100%;
      display: block;
    }
    .list-top-box {
      position: relative;
    }
    .tab-box {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      width: 288px;
      height: 44px;
      display: flex;
      align-items: flex-end;
      .tab-item {
        flex: 1;
        height: 40px;
        background: #595b68;
        border-radius: 4px 4px 0px 0px;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        transition: height 0.3s, background 0.3s;
      }
      .select-tab-item {
        height: 44px;
        background: #d5a581;
        border-radius: 4px 4px 0px 0px;
      }
    }
    .top-list-box {
      background: #ffffff;
      border: 1px solid #dedede;
      border-radius: 4px;
      margin: 0 35px;
      position: relative;
      height: 385px;
      overflow: hidden;

      .top-list-main {
        animation: move 25s linear infinite 1s;
        .top-list-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 95px;
          border-bottom: 1px solid #dedede;
          &:last-child {
            border: none;
          }
          img {
            width: 44px;
            height: 44px;
          }
          .list-text {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3f3f3f;
          }
        }
      }
    }
    @keyframes move {
      100% {
        transform: translateY(-50%);
      }
    }
  }
  .flex-box {
    position: absolute;
    transition: right 0.5s ease-out;
    top: 528px;
    right: -300px;
    width: 300px;
    // height: 880px;
    background: #ffffff;
    border: 1px solid #dedede;
    padding: 0 25px 25px 25px;
    box-sizing: border-box;
    .flex-item {
      margin-top: 30px;
      img {
        width: 47px;
        display: block;
      }
      .flex-item-label {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #5aa9ff;
        line-height: 100%;
        margin-top: 24px;
      }
      .flex-item-msg {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 100%;
        margin-top: 13px;
      }
      .flex-item-border {
        width: 200px;
        height: 1px;
        background: linear-gradient(90deg, #5aa9ff 0%, #ffffff 100%);
        opacity: 0.44;
        margin-top: 28px;
      }
    }
    .flex-btn {
      cursor: pointer;
      position: absolute;
      top: -1px;
      left: -40px;
      width: 40px;
      height: 40px;
      background: #0078fc;
      border-radius: 4px 0px 0px 4px;
      .flex-btn-img {
        width: 100%;
        margin-top: -3px;
        display: block;
        transform: rotate(-180deg);
        transition: transform 0.5s ease-out, margin-top 0.5s ease-out;
      }
      .flex-btn-img-show {
        margin-top: 3px;
        transform: rotate(0deg);
      }
    }
  }
  .flex-box-show {
    right: 60px;
  }
}
</style>
